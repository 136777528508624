.prace-page {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Delikatny cień */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  font-weight: bold;
  text-transform: uppercase;
}

td {
  font-size: 16px;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  opacity: 0.9; /* Efekt po najechaniu */
}

button.edit {
  background-color: #007bff; /* Kolor przycisku Edytuj */
  color: white;
}

button.delete {
  background-color: #dc3545; /* Kolor przycisku Usuń */
  color: white;
}

/* Przycisk do dodawania pracy */
button.add-praca {
  font-size: 20px;
  padding: 15px 30px;
  width: 250px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

button.add-praca:hover {
  background-color: #0056b3; /* Ciemniejszy kolor po najechaniu */
}

/* Stylowanie formularza w modalu */
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

input,
select,
textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

input[type="date"] {
  cursor: pointer;
}

textarea {
  resize: vertical;
  height: 100px;
}

/* Przyciski w formularzu */
form button[type="submit"] {
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
}

form button[type="submit"]:hover {
  background-color: #218838;
}

form button[type="button"] {
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
}

form button[type="button"]:hover {
  background-color: #c82333;
}

/* Stylizacja dla paginacji */
.pagination {
  display: flex;
  justify-content: center; /* Wyśrodkowanie przycisków paginacji */
  margin-top: 20px;
  gap: 10px; /* Odstęp między przyciskami */
}

.pagination button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 16px;
  cursor: pointer;
}

.pagination button.active {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.pagination button:hover {
  background-color: #0056b3;
  color: white;
}

.text-right {
  text-align: right;
}
