.login-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../public/tlomain.webp"); /* Odniesienie do pliku tła w public */
  background-size: cover;
  background-position: center;
  position: relative;
}

.login-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6); /* Warstwa półprzezroczysta */
  z-index: 1;
}

.login-container {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

label {
  display: block;
  font-size: 14px;
  color: #555;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
.footer {
  position: fixed; /* Ustawia stopkę na stałe na dole */
  bottom: 0; /* Pozycja stopki na dole */
  left: 0;
  width: 100%; /* Szerokość stopki na 100% */
  text-align: center;
  font-size: 15px;
  color: black;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Możesz dodać tło, aby było widoczne */
  padding: 10px 0;
  z-index: 1; /* Upewnij się, że stopka znajduje się nad tłem */
}

.footer p {
  margin: 0;
}
