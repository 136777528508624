.archive-page {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  font-weight: bold;
  text-transform: uppercase;
}

td {
  font-size: 16px;
}

button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-controls button {
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 16px;
  cursor: pointer;
}

.pagination-controls button.active {
  background-color: #007bff;
  color: white;
}

.pagination-controls button:hover {
  background-color: #0056b3;
  color: white;
}
.highlight {
  background-color: yellow; /* Podświetlanie komórek na żółto */
}

.details-header {
  display: flex;
  align-items: center; /* Wyrównaj elementy w pionie do środka */
  gap: 10px; /* Dodaj odstęp między nagłówkiem a przyciskiem */
}

.details-header h2 {
  margin: 0; /* Usuń domyślne marginesy nagłówka */
  font-size: 20px; /* Możesz dostosować rozmiar czcionki nagłówka */
}

.hide-details-small {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px; /* Mniejsze wymiary przycisku */
  font-size: 20px; /* Mniejszy rozmiar tekstu */
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px; /* Dodaj odstęp między nagłówkiem a przyciskiem */
}

.hide-details-small:hover {
  background-color: #c82333;
}
