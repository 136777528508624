.user-page {
  background-color: #ffffff; /* Białe tło */
  padding: 10px;
  border-radius: 10px; /* Zaokrąglone rogi */
  width: 95%;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Delikatny cień */
}

table {
  width: 100%;
  border-collapse: collapse; /* Usuwa odstępy między komórkami tabeli */
}

th,
td {
  padding: 10px; /* Zwiększenie odstępów między komórkami */
  text-align: left;
  border-bottom: 1px solid #ddd; /* Dodanie linii podziału */
}

th {
  font-weight: bold;
  text-transform: uppercase;
}

td {
  font-size: 16px; /* Ustawienie rozmiaru czcionki w komórkach */
}

.action-buttons {
  display: flex;
  gap: 10px; /* Odstęp między przyciskami */
}

button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  opacity: 0.9; /* Efekt po najechaniu */
}

button.edit {
  background-color: #007bff; /* Kolor przycisku Edytuj */
  color: white;
}

button.delete {
  background-color: #dc3545; /* Kolor przycisku Usuń */
  color: white;
}

/* Większy przycisk Dodaj użytkownika */
button.add-user {
  font-size: 20px;
  padding: 15px 30px;
  width: 250px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

button.add-user:hover {
  background-color: #0056b3; /* Ciemniejszy kolor po najechaniu */
}
