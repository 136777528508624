.menu {
  background-image: url("../public/tlomain2.jpg"); /* Odniesienie do pliku tła w public */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  width: 90%;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
}

.menu li {
  color: black; /* Czarne napisy */
  cursor: pointer;
  padding: 10px 15px;
  background-color: white; /* Białe tło */
  border: 1px solid #ccc; /* Subtelne obramowanie */
  border-radius: 5px;
  transition: background-color 0.3s ease, font-weight 0.3s ease, color 0.3s ease;
}

.menu li:hover {
  color: black; /* Czarne napisy na hover */
  background-color: #f1f1f1; /* Jaśniejsze tło na hover */
  font-weight: bold; /* Pogrubienie tekstu na hover */
}

.user-info {
  color: white; /* Biały kolor tekstu */
  padding: 15px;
  border-radius: 5px;
  max-width: 100%; /* Możesz ustawić 100% lub usunąć to ograniczenie */
  margin: 0 auto;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.user-details p {
  margin: 0;
  font-weight: bold;
  color: black;
}

.user-actions {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logout {
  color: black;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.logout:hover {
  color: blue;
  background-color: #f1f1f1;
}

.edit-icon {
  color: black;
  cursor: pointer;
  padding: 10px 15px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.edit-icon:hover {
  color: blue;
  background-color: #f1f1f1;
}
