.raport {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 18px; /* Większa czcionka dla tekstu */
}

.raport label {
  font-size: 20px; /* Większa czcionka dla etykiet */
  font-weight: bold; /* Pogrubienie etykiet */
  margin-bottom: 10px;
  display: block;
}

.raport select,
.raport input {
  font-size: 18px; /* Większa czcionka dla elementów wejściowych */
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.raport button {
  font-size: 20px; /* Większa czcionka dla przycisku */
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.raport button:hover {
  background-color: #0056b3;
}

.loader-container {
  display: flex;
  flex-direction: column; /* Ustawienie elementów w kolumnie (pionowo) */
  justify-content: center; /* Wyśrodkowanie w pionie */
  align-items: center; /* Wyśrodkowanie w poziomie */
  min-height: 20vh; /* Ustawienie kontenera na pełną wysokość ekranu */
  text-align: center; /* Wyśrodkowanie tekstu wewnątrz */
}
