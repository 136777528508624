.klienci-page {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  font-weight: bold;
  text-transform: uppercase;
}

td {
  font-size: 16px;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}

button.edit {
  background-color: #007bff;
  color: white;
}

button.delete {
  background-color: #dc3545;
  color: white;
}

button.add-client {
  font-size: 20px;
  padding: 15px 30px;
  width: 250px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}

button.add-client:hover {
  background-color: #0056b3;
}

.form-table {
  display: flex;
  flex-direction: row;
  gap: 20px; /* Odstęp między kolumnami */
  margin-bottom: 20px;
}

.table-row {
  display: flex;
  width: 100%;
}

.table-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Odstęp między polami w kolumnach */
}

.table-column input,
.table-column textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button.save {
  background-color: #28a745;
  color: white;
}

button.cancel {
  background-color: #dc3545;
  color: white;
}

button:hover {
  opacity: 0.9;
}
