/* Resetowanie globalnych marginesów i paddingów */
html,
body {
  margin: 0;
  padding: 0;
  width: 90%; /* Szerokość 100% dla całego okna */
  height: 90%;
  box-sizing: border-box;
}

.prace-page {
  padding: 20px;
  width: 90%; /* Szerokość na 100% widocznego okna przeglądarki */
  margin: 0 auto; /* Wyśrodkowanie */
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 kolumny w jednym wierszu */
  gap: 20px; /* Odstępy między kartami */
  width: 100%; /* Pełna szerokość siatki */
  margin: 0; /* Brak marginesów wokół */
  padding: 20px;
  box-sizing: border-box;
}

.user-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%; /* Karty zajmują pełną szerokość kolumny */
  display: flex;
  flex-direction: column;
  font-size: 18px; /* Większa czcionka */
}

.card-header {
  background-color: #3b82f6;
  color: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  text-align: center;
  font-size: 24px; /* Większa czcionka dla nagłówków */
}

.card-body {
  padding: 20px;
}

.work-item {
  background-color: #f5f5f5;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  font-size: 18px; /* Większa czcionka dla prac */
}

h2 {
  font-size: 22px;
}

strong {
  display: inline-block;
  width: 150px;
}

.action-buttons {
  margin-top: 10px;
}

.edit-btn,
.delete-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.edit-btn {
  color: green;
}

.delete-btn {
  color: red;
}

.edit-btn:hover,
.delete-btn:hover {
  opacity: 0.8;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Wysokość pełnego ekranu */
}
