.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Półprzezroczyste tło */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ustaw z-index, aby modal był na wierzchu */
}

.modal-content2 {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  width: 1200px; /* Stała szerokość modala */
  max-height: 75vh; /* Zwiększona wysokość, 80% wysokości ekranu */
  overflow-y: auto; /*  Dodaj przewijanie, jeśli treść przekroczy wysokość */
  box-sizing: border-box; /* Uwzględnij padding wewnątrz szerokości */
  display: flex;
  flex-direction: column; /* Ustaw kolumnowy układ wewnątrz modala */
}

.modal-close {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-close:hover {
  color: red;
}

/* Stylowanie kontenera na przyciski na dole modala */
.modal-buttons {
  display: flex;
  justify-content: space-between; /* Przycisk Zapisz i Anuluj obok siebie */
  margin-top: 20px;
  gap: 10px; /* Odstęp między przyciskami */
}

button {
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1; /* Przycisk rozciąga się na równą szerokość */
}

button:hover {
  opacity: 0.9; /* Efekt po najechaniu */
}

button.save {
  background-color: #28a745; /* Kolor przycisku Zapisz */
  color: white;
}

button.cancel {
  background-color: #dc3545; /* Kolor przycisku Odrzuć */
  color: white;
}

/* Ustawienia dla większej responsywności */
@media (max-width: 768px) {
  .modal-content {
    width: 90%; /* Zmniejsz szerokość modala na mniejszych ekranach */
  }

  button {
    padding: 10px; /* Dopasowanie wielkości przycisków na mniejszych ekranach */
  }
}
